import "./assets/tailwind.css";
import "setimmediate";

import { cleanLocalStorage } from "@dimensional-innovations/vuex-local-store";
import { name } from "../package.json";
import store from "./store";

cleanLocalStorage(new RegExp(`^${name}:`), [store.cacheKey]);

document.addEventListener("contextmenu", (e) => {
  e.preventDefault();
  return false;
});

if (process.env.IS_ELECTRON) {
  import("./main.electron");
} else {
  import("./main.web");
}
