import { post } from "./Api";

export interface CreateButterflyBase64Message {
  name: string;
  custom_image_base64: string;
  design_type: string;
}

export interface ButterflyMessage {
  id: number;
  name: string;
  design_type: string;
  custom_image_base64?: string;
  custom_image_file?: string;
  created: string;
  modified: string;
}

export function createButterfly(
  message: CreateButterflyBase64Message
): Promise<ButterflyMessage> {
  return post("/butterfly/", { custom_image_file: null, ...message });
}
