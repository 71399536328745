import { SocketClient } from "@dimensional-innovations/carilion-socket-client";

let webSocketUrl = "";

/**
 * Sets the url to the web socket server.
 *
 * @param url - The url of the web socket server.
 */
export function setWebSocketUrl(url: string): void {
  webSocketUrl = url;
}

/**
 * Connects to the web socket server to execute the provided delegate,
 * then closes the connection.
 *
 * @param delegate - The action to execute once connected to the
 *                   web socket server.
 * @returns - A promise that is resolved once the socket has connected
 *            and the provided action is complete.
 */
export async function useWebSocket(
  delegate: (socket: SocketClient) => void
): Promise<void> {
  const socket = new SocketClient({
    host: webSocketUrl,
    reconnect: false,
  });
  await socket.connect();

  delegate(socket);

  socket.close();
}
