import { createButterfly } from "@/api/ButterflyApi";
import { ButterflyDesignType } from "@/models/ButterflyDesignType";
import { InjectionKey } from "@vue/runtime-core";
import { Store } from "vuex";
import { name, version } from "../../package.json";
import { createStore } from "@dimensional-innovations/vuex-local-store";
import { sendButterflyCreated } from "@/api/ButterflyWebSocket";

export interface State {
  butterfly: {
    designType?: ButterflyDesignType;
    dataUrl?: string;
    name?: string;
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  cacheKey: `${name}:${version}`,
  cachePaths: ["butterfly"],
  state: {
    butterfly: {},
  },
  mutations: {
    reset: (state) => {
      state.butterfly = {};
    },
    setButterflyDesignType: (state, payload) => {
      state.butterfly.designType = payload;
      state.butterfly.dataUrl = undefined;
    },
    setButterflyDataUrl: (state, payload) => {
      state.butterfly.dataUrl = payload;
    },
    setButterflyName: (state, payload) => {
      state.butterfly.name = payload;
    },
  },
  actions: {
    async releaseButterfly(context) {
      const { name, dataUrl, designType } = context.state.butterfly;
      if (!name || !dataUrl || !designType) {
        return;
      }

      const butterfly = await createButterfly({
        name: name,
        custom_image_base64: dataUrl,
        design_type: designType,
      });
      await sendButterflyCreated(butterfly.id);
    },
  },
  modules: {},
});

/* eslint-disable */

export const getButterflyDesignType = (store: Store<State>): ButterflyDesignType | undefined => store.state.butterfly.designType;
export const getButterflyDataUrl = (store: Store<State>): string | undefined => store.state.butterfly.dataUrl;
export const getButterflyName = (store: Store<State>): string | undefined => store.state.butterfly.name;

export const reset = (store: Store<State>): void => store.commit("reset");
export const setButterflyDesignType = (store: Store<State>, designType: ButterflyDesignType): void => store.commit("setButterflyDesignType", designType);
export const setButterflyDataUrl = (store: Store<State>, dataUrl: string): void => store.commit("setButterflyDataUrl", dataUrl);
export const setButterflyName = (store: Store<State>, name: string): void => store.commit("setButterflyName", name);
export const releaseButterfly = (store: Store<State>): Promise<void> => store.dispatch("releaseButterfly");
