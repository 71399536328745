import axios from "axios";

let baseApiUrl = "";

/**
 * Sets the base url used by all api requests.
 *
 * @param url - The base url of the api.
 */
export function setBaseApiUrl(url: string): void {
  baseApiUrl = url;
}

/**
 * Adds a handler to the underlying api framework that is invoked when an api
 * call fails for any reason.
 *
 * @param handler - The method to call when an api call fails.
 */
export function addErrorHandler(handler: (error: any) => void): void {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      handler(error);
      return Promise.reject(error);
    }
  );
}

/**
 * Performs a post request against the configured api endpoint.
 *
 * @param url - The url of the api to send the request to. This should not include the base url of the api.
 * @param data - The body of the post request.
 * @returns - A promise that is resolved with the data once the request completes.
 */
export async function post<Response, Data>(
  url: string,
  data: Data
): Promise<Response> {
  const response = await axios.post<Response>(`${baseApiUrl}${url}`, data);
  return response.data;
}
